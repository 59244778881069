// рендерим капчу и создаем объект reCaptchaId
var reCaptchaId = {};
function reCapthcaRender(){
	$('.reCaptcha').each(function(){
		reCaptchaId[$(this).attr('id')] = grecaptcha.render($(this).attr("id"), {
      'sitekey' : '6Lcg36UUAAAAAJHCy3IQSESpe8oMlyGszWkpstPF'
    });
	});
};
$(document).ready(function(){
	// Кнопка формы
	$('.checkbox').change(function(){
		if($(this).prop("checked"))
			$('.download-form__btn').prop('disabled', false);
		else
			$('.download-form__btn').prop('disabled', true);
	});
	$('.questions-checkbox').change(function(){
		if($(this).prop("checked"))
			$('.questions-form__btn').prop('disabled', false);
		else
			$('.questions-form__btn').prop('disabled', true);
	});
	$('.popup-checkbox').change(function(){
		if($(this).prop("checked"))
			$('.popup-btn').prop('disabled', false);
		else
			$('.popup-btn').prop('disabled', true);
	});
	/* ------------------------------------- */

	// фансибокс
	$('.fancybox').fancybox({
		helpers: {
		 overlay: {
      	closeClick: true,
      	locked: false
     }	
		}
	});
	/* ------------------------------------- */
	// popup
	$('.popup-open').click(function(){
		$('.overlay').show();
		event.preventDefault();
	});
	$('.getPlan').click(function(){
		$('.overlay').show();
		// $('#popup-phone').attr('hidden', true);
		// $('#popup-email').attr('hidden', false);
		$('.form-phone-hide').hide();
		$('.form-email-hide').show();
	})
	$('.popup-close').click(function(){
		$('.overlay').hide();
		$('#popup-phone').val('');
		$('#popup-email').val('');
		$('.form-phone-hide').show();
		$('.form-email-hide').hide();
	});
	/* ------------------------------------- */
	// заголовки в попапе
	$('.main-consultation__btn').click(function(){
		$('.popup-title').html('Записаться на пробную лекцию');
	});
	$('.getCourse').click(function(){
		$('.popup-title').html('Заказать курс');
	});
	$('.getPlan').click(function(){
		$('.popup-title').html('Получить учебный план');
	});
	$('.popup-close').click(function(){
		$('.popup-title').html('Заказать звонок');
	});
	/* ------------------------------------- */
	// popup-conf
	$('.open-conf').click(function(){
		$('.overlay-conf').show();
	});
	$('.conf-close').click(function(){
		$('.overlay-conf').hide();
	});
	/* ------------------------------------- */
	// форма
	$('.form').submit(function(event){
		event.preventDefault();
		var form = $(this); //присваем форму 
				phone = form.find("input[name='phone']").val(); //присваеваем значения поля input
				email = form.find("input[name='email']").val(); //присваеваем значения поля input
				popupEmail = form.find("input[name='popup-email']").val(); //присваеваем значения поля input
				reCaptcha = form.find("textarea[name='g-recaptcha-response']").val(); //присваеваем значения поля g-recaptcha-response
				property = form.find('.reCaptcha').attr('id'); // присваеваем свойство re-Captcha-i
				reCaptchaNumber = reCaptchaId[property]; // присваеваем значение свойства re-Captcha-i
				if(phone){
					var input = phone;
				}
				if(email){
					var input = email;
				}
				if(popupEmail){
					var input = popupEmail;
				}
		form.find('.error').text('');
		form.find('.success').text('');
		if( reCaptcha && input){
			$.ajax({
				type : "POST",
				url: ('/captcha.php'),
				data: form.serialize(),
				success: function(data){
					grecaptcha.reset(reCaptchaNumber);
					if ($(form).hasClass('download-form')) {
						$('.overlay-success').show();
						setTimeout(function(){
							$('.overlay-success').hide(500, 'linear');
						}, 2000);
					} else {
						form.find('.success').text("Форма успешно отправлена!");
					}
				},
				error: function(error){
					form.find('.error').text(error.responseText);
					grecaptcha.reset(reCaptchaNumber);
				}
			});
		}
		else {
			$(this).find('.error').text('Заполните обязательные поля');
		}
	});
	/* ------------------------------------- */
	/*MaskedInput*/
	//$('#popup-phone').mask('8(999)999-99-99 ');
	//$('#download-phone').mask('8(999)999-99-99 ');
	//$('#questions-phone').mask('8(999)999-99-99 ');
	/*----------*/
});